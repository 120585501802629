import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/index";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "index",
    component: Index,
  },
  {
    path: "/questionType",
    name: "questionType",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/questionType"),
  },
  {
    path: "/riskQuestion",
    name: "riskQuestion",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/questionType/riskQuestion.vue"),
  },
  {
    path: "/questionList",
    name: "questionList",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/questionType/questionList.vue"),
  },
  {
    path: "/answerPage",
    name: "answerPage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/questionType/answerPage.vue"),
  },
  {
    path: "/answerPageRisk",
    name: "answerPageRisk",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/questionType/answerPageRisk.vue"),
  },
  {
    path: "/preemptivePage",
    name: "preemptivePage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/questionType/preemptivePage.vue"),
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;