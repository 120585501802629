<template>
  <div class="index-box home-box">
    <div class="content-box">
      <!-- <img src="@/assets/images/bgLine.png" alt class="bg-line" />
      <div class="title">
        <span>踔厉奋发新征程 支付清算向未来</span>
      </div>
      <div class="activity-text">
        <span>保定市银行业金融机构知识竞赛决赛</span>
      </div>
      <div class="activity-date">
        2023
        <span style="margin-left:0.15rem">·</span>11
      </div> -->
      <div class="answer-btn" @click="goChooseType">进入答题 ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {};
  },
  methods: {
    goChooseType() {
      this.$router.push("/questionType");
    },
  },
};
</script>


<style scoped>
.index-box {
  width: 100%;
  height: 100vh;
  background: url("./../../assets/images/bgIndex.png") no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 2.48rem;
  position: relative;
  z-index: 1;
}
.home-box {
  padding-top: 0;
  background: url("./../../assets/images/homeBg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
}
.content-box {
}
.content-box .bg-line {
  position: absolute;
  width: 17.53rem;
  height: 2.92rem;
  top: 0.4rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
.content-box .title {
  height: 1.21rem;
  position: relative;
}
.content-box .title span {
  margin-top: 0.1rem;
  font-size: 1.26rem;
  font-family: MFLiHei_Noncommercial;
  font-weight: 400;
  color: #292929;
  line-height: 1;
  position: relative;
}
.content-box .title span::after {
  content: "踔厉奋发新征程    支付清算向未来";
  margin-top: 0.1rem;
  font-size: 1.26rem;
  font-family: MFLiHei_Noncommercial;
  font-weight: 400;
  color: #fdfefe;
  line-height: 1;
  position: absolute;
  top: 0.05rem;
  left: -0.09rem;
}
.activity-text {
  /* width: 17.1rem; */
  height: 0.66rem;
  margin-top: 0.63rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("./../../assets/images/whiteline.png") no-repeat;
  background-size: 17.1rem 0.05rem;
  background-position: center;
}

.activity-text span {
  font-size: 0.68rem;
  font-family: MFLiHei_Noncommercial;

  color: #000000;
  margin-top: 0.2rem;
  /* line-height: 0.66rem; */
  position: relative;
  margin-left: 0.1rem;
}
.activity-text span::after {
  content: "保定市银行业金融机构知识竞赛决赛";
  font-size: 0.68rem;
  font-family: MFLiHei_Noncommercial;

  color: #fcfcf9;
  margin-top: 0.1rem;
  line-height: 1;
  position: absolute;
  top: -0.05rem;
  left: -0.05rem;
}
.activity-date {
  font-family: MFLiHei_Noncommercial;
  font-size: 0.42rem;
  color: #fbf7f7;
  margin-top: 1.06rem;
  line-height: 0.46rem;
}
.answer-btn {
  /* width: 5.41rem;
  height: 1.62rem; */
  /* width: 4.12rem; */
  /* height: 100vh; */
  /* height: 1.3rem; */
  width: 2.8rem;
  height: 1rem;
  font-size: 0.4rem;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #ffffff;
  line-height: 1.62rem;
  letter-spacing: 0.01rem;
  /* margin: 0.96rem auto 0; */
  /* background: url("./../../assets/images/btnBg.png"); */
  /* margin: 0 auto; */
  /* background-image: url("./../../assets/images/btnBgNew.png"); */
  background-image: url("./../../assets/images/btnBgNew1.png");
  background-repeat: no-repeat;
  /* background-size: 3.66rem 1.08rem; */
  /* background-position: center; */
  background-size: contain;
  /* background-position: center 66%; */
  background-position: center;
  cursor: pointer;
  opacity: 0.5;
  position: absolute;
  bottom: 3%;
  right: 2%;
  color: rgba(255, 243, 189, 0.8);
  line-height: 1rem;
  text-align: center;
  font-size: 0.3rem;
}
</style>